import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    data: null,
    status: "idle",
    error: ""
};
var shoppingCart = createSlice({
    name: 'shoppingCart',
    initialState: initialState,
    reducers: {
        setDataShoppingCart: function (state, _a) {
            var data = _a.payload.data;
            state.data = data;
        },
    },
});
export var setDataShoppingCart = shoppingCart.actions.setDataShoppingCart;
//Fetch Data Cart
export var getDataShoppingCart = function (state) { var _a; return ((_a = state === null || state === void 0 ? void 0 : state.shoppingCart) === null || _a === void 0 ? void 0 : _a.data) ? state.shoppingCart.data : null; };
export default shoppingCart.reducer;
