var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getNotificationData, markAsReadNotification } from 'api/notification-repository';
import NotificationsIcon from '../../../assets/images/new_noti.png';
//Action Store
import { useAppSelector } from '../../../utils/hook';
import { selectAllNotifications } from '../../../store/notification/slice';
import { useTranslation } from 'react-i18next';
import * as Constant from '../../../utils/Constants';
import { getMe } from 'api';
import { USER_DATA_KEY } from 'utils/StorageKeys';
import { getItem, storeItem } from 'utils/LocalStorageUtil';
import { useNavigate } from 'react-router-dom';
var NotificationDropdown = function (props) {
    var notificationGlobalCountData = props.notificationGlobalCountData;
    var navigate = useNavigate();
    var user = getItem(USER_DATA_KEY);
    var t = useTranslation().t;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(true), hasMore = _c[0], setMore = _c[1];
    var _d = useState([]), notificationData = _d[0], setNotificationData = _d[1];
    var _e = useState({
        size: 10,
        page: 0
    }), notificationOption = _e[0], setOption = _e[1];
    var _f = useState(0), numberNotRead = _f[0], setNumberNotRead = _f[1];
    // selectors to access state
    var notificationGlobalSelectors = useAppSelector(selectAllNotifications);
    var useOutsideAlerter = function (ref) {
        useEffect(function () {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return function () {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    var notificationRef = useRef(null);
    useOutsideAlerter(notificationRef);
    // useEffect(() => {
    //     getNotification("DEFAULT");
    // }, [])
    useEffect(function () {
        if (notificationGlobalSelectors) {
            setNumberNotRead(notificationGlobalSelectors);
        }
    }, [notificationGlobalSelectors]);
    var getNotification = function (type) { return __awaiter(void 0, void 0, void 0, function () {
        var size, page, params, data, result, notificationPush, affiliateRequestNotification, userInfo, data_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    setLoading(true);
                    size = notificationOption.size;
                    page = notificationOption.page;
                    if (type === "DEFAULT") {
                        page = 0;
                    }
                    else {
                        page = page + 1;
                    }
                    params = {
                        page: page,
                        size: size
                    };
                    return [4 /*yield*/, getNotificationData(params)];
                case 1:
                    data = _a.sent();
                    return [4 /*yield*/, getMe()];
                case 2:
                    result = _a.sent();
                    setOption({
                        page: page,
                        size: size
                    });
                    notificationPush = type ? data && data.data : notificationData.concat(data.data);
                    setMore(data.data.length >= 10);
                    setNotificationData(notificationPush);
                    affiliateRequestNotification = data.data.find(function (data) { return data.type.name === Constant.NOTIFICATION_APPROVED_AFFILIATE || data.type.name === Constant.NOTIFICATION_REJECTED_AFFILIATE; });
                    if (affiliateRequestNotification) {
                        userInfo = JSON.parse(user);
                        data_1 = {};
                        if (affiliateRequestNotification.type.name === Constant.NOTIFICATION_APPROVED_AFFILIATE) {
                            data_1 = __assign(__assign({}, userInfo), { affiliateStatus: 1 });
                            storeItem(USER_DATA_KEY, JSON.stringify(data_1));
                        }
                        else if (affiliateRequestNotification.type.name === Constant.NOTIFICATION_REJECTED_AFFILIATE) {
                            data_1 = __assign(__assign({}, userInfo), { affiliateStatus: 2 });
                            storeItem(USER_DATA_KEY, JSON.stringify(data_1));
                        }
                    }
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClick = function (event) {
        setOpen(!open);
        if (!open) {
            getNotification("DEFAULT");
        }
    };
    var onClickNotification = function (event, notification) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!(notification && notification.contentData && notification.contentData.entityDetailLink)) return [3 /*break*/, 2];
                    return [4 /*yield*/, markAsReadNotification(notification.id, { status: 2 })];
                case 1:
                    _a.sent();
                    window.location.replace(notification.contentData.entityDetailLink);
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "dropdowns-wrapper mr-3 max-[500px]:mr-0", ref: notificationRef },
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("div", { className: "notifications dropdown dd-trigger" },
                React.createElement("a", { className: "\n                            cursor-pointer\n                            text-gray-500\n                            hover:text-gray-700\n                            focus:text-gray-700\n                            mr-4\n                            dropdown-toggle\n                            hidden-arrow\n                            flex items-center\n                        ", onClick: handleClick, role: "button", "aria-expanded": "false" },
                    React.createElement("img", { className: 'w-8 h-8 max-sm:w-6 max-sm:h-6', src: NotificationsIcon, alt: "obranding-notification" }),
                    numberNotRead ? React.createElement("span", { className: "notifications-number text-brand bg-red-700 absolute rounded-full text-xs -mt-4 ml-4 py-0 px-1.5" }, numberNotRead) : null)),
            open ? (React.createElement("div", { className: "custom-select-dropdown custom-select-notification arrow", style: { position: "absolute", zIndex: 99999999 } },
                React.createElement("div", { className: "dropdown-header" },
                    React.createElement("span", { className: "triangle" }),
                    React.createElement("span", { className: "heading" }, t("common.notification"))),
                React.createElement("div", { className: "dropdown-body dropdown-body-notification", id: "scrollableNotification" },
                    React.createElement(InfiniteScroll, { dataLength: notificationData.length, next: getNotification, hasMore: hasMore, loader: React.createElement("div", { className: 'text-center' },
                            React.createElement("span", null, "Loading....")), scrollableTarget: "scrollableNotification", endMessage: React.createElement("p", { className: 'text-center' },
                            React.createElement("b", null, t("common.notification_see_all"))) }, notificationData && notificationData.length ? notificationData.map(function (notification, index) {
                        return React.createElement("div", { key: index, className: "notification ".concat(notification && notification.status == 2 ? "" : "new"), onClick: function (e) { return onClickNotification(e, notification); } },
                            React.createElement("div", { className: "notification-image-wrapper" },
                                React.createElement("div", { className: "notification-image" }, notification.contentData && (notification.contentData.brandLogoUrl || notification.contentData.logoUrl) ? React.createElement("img", { src: notification.contentData.brandLogoUrl || notification.contentData.logoUrl, alt: "obranding-brand-logo", width: "32" }) :
                                    React.createElement("svg", { "aria-hidden": "true", focusable: "false", "data-prefix": "fas", "data-icon": "bell", className: "w-4", role: "img", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 448 512" },
                                        React.createElement("path", { fill: "currentColor", d: "M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z" })))),
                            React.createElement("div", { className: "notification-text" },
                                React.createElement("p", null, notification.message),
                                React.createElement("p", { className: 'from-time-text' }, process.env.TIME_ZONE ? moment(notification.createdAt).subtract(7, 'h').lang("vi").fromNow() : moment(notification.createdAt).lang("vi").fromNow()),
                                React.createElement("p", { className: 'delete-icon' })));
                    }) : null)),
                React.createElement("div", { className: "dropdown-footer" }))) : null),
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("div", { className: "messages dropdown" },
                React.createElement("span", { className: "fa fa-envelope-o" })))));
};
export default NotificationDropdown;
