import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { checkUIPermission, isAuthenticated } from "utils/CommonUtil";
import { storeItem } from "utils/LocalStorageUtil";
import { LOGIN_REFERER } from "utils/StorageKeys";
var PrivateRoute = function (_a) {
    var children = _a.children;
    var isAuthen = isAuthenticated();
    if (!isAuthen) {
        var location_1 = useLocation();
        storeItem(LOGIN_REFERER, location_1 && location_1.pathname ? location_1.pathname : "");
    }
    var accessible = checkUIPermission(location.pathname, true);
    return isAuthen ? (accessible ? (React.createElement(Outlet, null)) : (React.createElement(Navigate, { to: "/" }))) : (React.createElement(Navigate, { to: "/login" }));
};
export default PrivateRoute;
