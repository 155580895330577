var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isString } from "lodash";
import { isManipulatable } from "utils/CommonUtil";
var get = function (key) {
    if (typeof window !== "undefined") {
        if (isManipulatable(key) && isManipulatable(localStorage)) {
            try {
                var localItem = localStorage.getItem(key);
                if (isString(localItem))
                    return JSON.parse(localItem);
            }
            catch (e) {
                console.error(e);
            }
        }
        return null;
    }
};
var set = function (key, value) {
    return (isManipulatable(key) &&
        isManipulatable(localStorage) &&
        localStorage.setItem(key, isManipulatable(value) && !isString(value) ? JSON.stringify(value) : value));
};
var remove = function (key) {
    return isManipulatable(key) &&
        isManipulatable(localStorage) &&
        localStorage.removeItem(key);
};
var update = function (key, value) {
    if (!isManipulatable(key) || isManipulatable(localStorage))
        return;
    var oldValue = get(key);
    localStorage.setItem(key, __assign(__assign({}, oldValue), value));
};
var Storage = { get: get, set: set, remove: remove, update: update };
export default Storage;
