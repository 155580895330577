import { TOKEN_KEY, REFRESH_TOKEN_KEY, OPENLIVE_TOKEN_KEY, OPENLIVE_REFRESH_TOKEN_KEY, PERMISSION_DATA_KEY } from "./StorageKeys";
export var LANGUAGE_CODE = "language";
export var storeItem = function (key, data) {
    localStorage.setItem(key, data);
};
export var removeItem = function (key) {
    localStorage.removeItem(key);
};
export var getItem = function (key) {
    return localStorage.getItem(key);
};
export var getLocalRefreshToken = function () {
    var refreshToken = getItem(REFRESH_TOKEN_KEY);
    return refreshToken;
};
export var getLocalAccessToken = function () {
    var accessToken = getItem(TOKEN_KEY);
    return accessToken;
};
export var updateLocalAccessToken = function (token) {
    var accessToken = token;
    localStorage.setItem(TOKEN_KEY, accessToken);
};
export var updateLocalRefreshToken = function (token) {
    var accessToken = token;
    localStorage.setItem(REFRESH_TOKEN_KEY, accessToken);
};
export var getAccessToken = function () {
    var token = getItem(TOKEN_KEY);
    return token;
};
export var getRefreshToken = function () {
    var refreshToken = getItem(REFRESH_TOKEN_KEY);
    return refreshToken;
};
export var setAccessToken = function (token) {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};
export var setRefreshToken = function (token) {
    localStorage.setItem(REFRESH_TOKEN_KEY, token);
};
export var removeAccessToken = function () {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(OPENLIVE_TOKEN_KEY);
    localStorage.removeItem(PERMISSION_DATA_KEY);
};
export var removeRefreshToken = function () {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(OPENLIVE_REFRESH_TOKEN_KEY);
    localStorage.removeItem(PERMISSION_DATA_KEY);
};
export var storeSessionItem = function (key, data) {
    sessionStorage.setItem(key, data);
};
export var removeSessionItem = function (key) {
    sessionStorage.removeItem(key);
};
export var getSessionItem = function (key) {
    return sessionStorage.getItem(key);
};
