import { QueryClient } from "@tanstack/react-query";
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            cacheTime: Infinity,
        },
    },
});
export default queryClient;
