var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import './index.scss';
import { getCodeCheckInWhitelist } from "api/whitelist-repository";
import { decodeToken } from "react-jwt";
import moment from "moment";
import { MyContext } from "../../../header-bar/Header";
import { useTranslation } from 'react-i18next';
var timeExpire = 5;
var ModalQrCode = function (props) {
    var setOpenModalQRCode = useContext(MyContext).setOpenModalQRCode;
    var openModalQRCode = props.openModalQRCode;
    var t = useTranslation().t;
    var _a = useState(""), dataCode = _a[0], setDataCode = _a[1];
    var _b = useState(timeExpire), remainingTime = _b[0], setRemainingTime = _b[1];
    useEffect(function () {
        if (openModalQRCode) {
            fetchDataCode();
        }
    }, [openModalQRCode]);
    useEffect(function () {
        var interval = setInterval(function () {
            setRemainingTime(function (prevCountdown) { return prevCountdown - 1; });
        }, 1000);
        if (remainingTime === 0) {
            clearInterval(interval);
        }
        return function () {
            clearInterval(interval);
        };
    }, [remainingTime]);
    var fetchDataCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, decode, diffSeconds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCodeCheckInWhitelist()];
                case 1:
                    data = _a.sent();
                    setDataCode(data);
                    decode = decodeToken(data);
                    diffSeconds = decode.exp ? moment.unix(decode.exp).diff(moment(), 'seconds') : 0;
                    setRemainingTime(diffSeconds > 0 ? diffSeconds : 0);
                    return [2 /*return*/];
            }
        });
    }); };
    var modalMemoRef = useRef(null);
    var useOutsidePopUp = function (ref) {
        useEffect(function () {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenModalQRCode(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return function () {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    useOutsidePopUp(modalMemoRef);
    return (React.createElement("div", { className: "modal-create-campaign modal fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto", id: "exampleModalCenteredScrollable", tabIndex: -1, "aria-labelledby": "exampleModalCenteredScrollable", "aria-modal": "true", role: "dialog" },
        React.createElement("div", { className: "modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none justify-center" },
            React.createElement("div", { ref: modalMemoRef, className: "modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current" },
                React.createElement("div", { className: "modal-header m-6 flex flex-shrink-0 items-center justify-between border-b border-gray-200 rounded-t-md" },
                    React.createElement(XMarkIcon, { className: 'w-6 h-6 stroke-2 cursor-pointer', onClick: function () { return setOpenModalQRCode(false); } })),
                React.createElement("div", { className: "m-6" }, dataCode &&
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "form-group mb-6 w-full text-center" },
                            React.createElement("div", { className: 'text-base font-semibold mb-2' }, t("common.your_qr_code")),
                            React.createElement(QRCode, { value: dataCode, viewBox: "0 0 256 256" })),
                        remainingTime > 0 &&
                            React.createElement("div", { className: "text-center" },
                                t("common.remaining_time"),
                                ": ",
                                remainingTime),
                        remainingTime <= 0 &&
                            React.createElement("div", { className: "text-center button-send-again", onClick: function () { return fetchDataCode(); } }, t("common.get_qr_code_again"))))))));
};
export default ModalQrCode;
