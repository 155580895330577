import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import * as LocalStorageUtil from "./utils/LocalStorageUtil";
i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
    defaultNS: "common",
    lng: LocalStorageUtil.getItem(LocalStorageUtil.LANGUAGE_CODE),
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
    fallbackLng: "vn",
    backend: {
        loadPath: "/static/locales/{{lng}}/{{ns}}.json",
    },
});
export default i18n;
