var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getMe } from "api";
import { requestToken } from "api/authenticate";
import { getCartTemp } from "api/cart-reponsitory";
import { COUNT_USERS_REPORT_NEW_INTERVAL, GLOBAL_UPDATE_INTERVAL, } from "config";
import { useEffect } from "react";
import { userStorage } from "services";
import { clearProductToCookie, getProductsFromCookie, getShoppingCartView, mergeLocalAndUserCart, } from "services/cart-serivce";
import store from "store";
import { fetchCountUsersReportNew } from "store/brands/thunks";
import { setNotificationGlobalInfo } from "store/notification/slice";
import { fetchGlobalNotifications } from "store/notification/thunks";
import { setDataShoppingCart } from "store/shopping-cart/slice";
import { getItem, storeItem } from "utils/LocalStorageUtil";
import { OPENLIVE_REFRESH_TOKEN_KEY, OPENLIVE_TOKEN_KEY, REFRESH_TOKEN_KEY, TOKEN_KEY, } from "utils/StorageKeys";
var AuthenticatorProvider = function (_a) {
    var children = _a.children;
    var intervalGlobal = null;
    var countUsersReport = null;
    var getCartUserInterval = null;
    useEffect(function () {
        var userData = userStorage.get();
        var tokenOpenLive = getItem(OPENLIVE_TOKEN_KEY);
        if (tokenOpenLive && !userData) {
            requestTokenData(tokenOpenLive);
        }
        getUserCart();
        getLocalCart();
        checkNotificationGlobal();
        if (!countUsersReport) {
            getCountUserReportInterval();
            countUserReportsNew();
        }
        return function () {
            setNotificationGlobalInfo({ data: null });
            if (intervalGlobal) {
                clearInterval(intervalGlobal);
            }
            if (countUsersReport) {
                clearInterval(countUsersReport);
            }
            if (getCartUserInterval) {
                clearInterval(getCartUserInterval);
            }
        };
    }, []);
    var requestTokenData = function (tokenOpenLive) { return __awaiter(void 0, void 0, void 0, function () {
        var param, result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    param = {
                        accessToken: tokenOpenLive,
                    };
                    return [4 /*yield*/, requestToken(param)];
                case 1:
                    result = _a.sent();
                    if (result && result.token) {
                        storeItem(TOKEN_KEY, result.token);
                        storeItem(REFRESH_TOKEN_KEY, result.refreshToken);
                        if (result.openlive) {
                            storeItem(OPENLIVE_TOKEN_KEY, result.token);
                            storeItem(OPENLIVE_REFRESH_TOKEN_KEY, result.refreshToken);
                        }
                    }
                    if (result && result.user) {
                        userStorage.set(result.user);
                    }
                    return [4 /*yield*/, getMe()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log("Error ====", error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var countUserReportsNew = function () {
        if (COUNT_USERS_REPORT_NEW_INTERVAL) {
            countUsersReport = setInterval(function () {
                getCountUserReportInterval();
            }, COUNT_USERS_REPORT_NEW_INTERVAL);
        }
    };
    var checkNotificationGlobal = function () {
        if (GLOBAL_UPDATE_INTERVAL) {
            intervalGlobal = setInterval(function () {
                getNotificationGlobalInterval();
            }, GLOBAL_UPDATE_INTERVAL);
        }
    };
    var getNotificationGlobalInterval = function () { return __awaiter(void 0, void 0, void 0, function () {
        var access_token;
        return __generator(this, function (_a) {
            access_token = getItem(TOKEN_KEY);
            if (access_token) {
                store.dispatch(fetchGlobalNotifications());
            }
            return [2 /*return*/];
        });
    }); };
    var getCountUserReportInterval = function () { return __awaiter(void 0, void 0, void 0, function () {
        var access_token;
        return __generator(this, function (_a) {
            access_token = getItem(TOKEN_KEY);
            if (access_token) {
                store.dispatch(fetchCountUsersReportNew({
                    statues: 0,
                    page: 0,
                    size: 999,
                }));
            }
            return [2 /*return*/];
        });
    }); };
    var getUserCart = function () {
        var loadingCart = false;
        getCartUserInterval = setInterval(function () { return __awaiter(void 0, void 0, void 0, function () {
            var access_token, localCart, data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        access_token = getItem(TOKEN_KEY);
                        if (!(access_token && !loadingCart)) return [3 /*break*/, 9];
                        localCart = getProductsFromCookie();
                        data = null;
                        if (!(localCart && localCart.length > 0)) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 5]);
                        loadingCart = true;
                        return [4 /*yield*/, mergeLocalAndUserCart(localCart)];
                    case 2:
                        data = _a.sent();
                        // console.log("data", data);
                        if (data) {
                            clearProductToCookie();
                            store.dispatch(setDataShoppingCart({ data: data }));
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        error_2 = _a.sent();
                        clearProductToCookie();
                        loadingCart = true;
                        return [4 /*yield*/, getShoppingCartView()];
                    case 4:
                        data = _a.sent();
                        return [3 /*break*/, 5];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        loadingCart = true;
                        return [4 /*yield*/, getShoppingCartView()];
                    case 7:
                        data = _a.sent();
                        _a.label = 8;
                    case 8:
                        if (data) {
                            clearInterval(getCartUserInterval);
                            loadingCart = false;
                        }
                        _a.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        }); }, 1000);
    };
    var getLocalCart = function () { return __awaiter(void 0, void 0, void 0, function () {
        var access_token, localCart, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    access_token = getItem(TOKEN_KEY);
                    if (!!access_token) return [3 /*break*/, 2];
                    localCart = getProductsFromCookie();
                    if (!(localCart && localCart.length > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getCartTemp(localCart)];
                case 1:
                    result = _a.sent();
                    store.dispatch(setDataShoppingCart({ data: result.data }));
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    return children;
};
export default AuthenticatorProvider;
