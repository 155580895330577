var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var Currency = {
    USD: 1,
    VND: 0
};
export var calculateCarts = function (dataCart, shoppingItemCart, adjustCount) {
    if (!dataCart)
        return;
    var dataCartUpdate = __assign({}, dataCart);
    var orderPrice = 0;
    var orderVNDSavingPrice = 0;
    var orderVNDOriginalPrice = 0;
    var indexTotal = -1;
    if (dataCartUpdate && dataCartUpdate.shoppingCartDetails && dataCartUpdate.shoppingCartDetails.length) {
        dataCartUpdate.shoppingCartDetails = dataCartUpdate.shoppingCartDetails.map(function (shoppingCartDetail, index) {
            var shoppingCartDetailData = __assign({}, shoppingCartDetail);
            if (shoppingCartDetailData.id && shoppingCartDetailData.id === "TOTAL") {
                indexTotal = index;
                return shoppingCartDetailData;
            }
            if (shoppingCartDetailData && shoppingCartDetailData.id === shoppingItemCart.id) {
                shoppingCartDetailData.numOfItem = adjustCount;
            }
            shoppingCartDetailData = calculateCartForItem(shoppingCartDetailData, shoppingCartDetailData.numOfItem);
            orderPrice = orderPrice + shoppingCartDetailData.totalVNDPrice;
            orderVNDSavingPrice = orderVNDSavingPrice + shoppingCartDetailData.totalVNDSavingPrice;
            orderVNDOriginalPrice = orderVNDOriginalPrice + shoppingCartDetailData.totalVNDOriginalPrice + shoppingCartDetailData.totalVNDOptionItemPrice;
            return shoppingCartDetailData;
        });
        if (indexTotal > -1) {
            dataCartUpdate.shoppingCartDetails[indexTotal].totalVNDPrice = orderPrice;
            dataCartUpdate.shoppingCartDetails[indexTotal].totalVNDSavingPrice = orderVNDSavingPrice;
            dataCartUpdate.shoppingCartDetails[indexTotal].totalVNDOriginalPrice = orderVNDOriginalPrice;
        }
    }
    // Remove item if count = 0
    if (dataCartUpdate && dataCartUpdate.shoppingCartDetails && dataCartUpdate.shoppingCartDetails.length) {
        dataCartUpdate.shoppingCartDetails = dataCartUpdate.shoppingCartDetails.filter(function (shoppingCartDetail) {
            return shoppingCartDetail.numOfItem > 0;
        });
    }
    dataCartUpdate.totalVNDPrice = orderPrice;
    dataCartUpdate.totalVNDSavingPrice = orderVNDSavingPrice;
    dataCartUpdate.totalVNDOriginalPrice = orderVNDOriginalPrice;
    return dataCartUpdate;
};
export var calculateCartForItem = function (shoppingCartItemData, adjustCount) {
    if (!shoppingCartItemData)
        return;
    var shoppingCartItem = __assign({}, shoppingCartItemData);
    var itemPrice = 0;
    var listingPrice = 0;
    var savingPrice = 0;
    var originalPrice = 0;
    var totalVNDOptionItemPrice = 0;
    var discountPercent = 0;
    // revert the array allSalePromotion
    // shoppingCartItem.allSalePromotion = shoppingCartItem.allSalePromotion ? shoppingCartItem.allSalePromotion.reverse() : [];
    var foundSalePromotion = null;
    if (shoppingCartItem && shoppingCartItem.allSalePromotion && shoppingCartItem.allSalePromotion.length) {
        var cloneSalePromotions = __spreadArray([], shoppingCartItem.allSalePromotion, true);
        // Sort by quantity
        cloneSalePromotions = cloneSalePromotions.sort(function (a, b) {
            var parseNumberQuantityA = a && a.value && a.value.quantity ? Number(a.value.quantity) : 0;
            var parseNumberQuantityB = b && b.value && b.value.quantity ? Number(b.value.quantity) : 0;
            return parseNumberQuantityA - parseNumberQuantityB;
        });
        cloneSalePromotions = cloneSalePromotions.reverse();
        console.log(cloneSalePromotions);
        foundSalePromotion = cloneSalePromotions.find(function (salePromotion) {
            // find next sale promotion
            var parseNumberQuantity = salePromotion && salePromotion.value && salePromotion.value.quantity ? Number(salePromotion.value.quantity) : 0;
            var parseNumberDiscount = salePromotion && salePromotion.value && salePromotion.value.discount ? Number(salePromotion.value.discount) : 0;
            if (parseNumberQuantity && !isNaN(parseNumberQuantity) && !isNaN(parseNumberDiscount) && parseNumberQuantity <= adjustCount) {
                return true;
            }
            return false;
        });
    }
    if (foundSalePromotion) {
        shoppingCartItem.salePromotion = foundSalePromotion;
        var parseNumberDiscount = Number(foundSalePromotion.value.discount) > 100 ? 100 : Number(foundSalePromotion.value.discount);
        discountPercent = parseNumberDiscount > 100 ? 100 : parseNumberDiscount;
    }
    else {
        shoppingCartItem.salePromotion = null;
    }
    var item = shoppingCartItem.item ? shoppingCartItem.item : null;
    var rateAfterDiscount = discountPercent > 0 ? (100 - discountPercent) / 100 : 1;
    var initialPrice = item && item.listingPrice === null ? item.currentPrice : item.listingPrice;
    var discountedPrice = item.currentPrice ? item.currentPrice * rateAfterDiscount : 0;
    if (item.saleCurrency == Currency.USD) {
        // itemPrice = discountedPrice * usdtVNDRate.rate;
        // savingPrice = ((initialPrice - discountedPrice) * usdtVNDRate.rate) > 0 ? (initialPrice - discountedPrice) * usdtVNDRate.rate : 0;
        // originalPrice = initialPrice * usdtVNDRate.rate;
        // listingPrice = item.listingPrice * usdtVNDRate.rate > 0 ? item.listingPrice * usdtVNDRate.rate : item.currentPrice * usdtVNDRate.rate;
    }
    else if (item.saleCurrency == Currency.VND) {
        itemPrice = discountedPrice;
        savingPrice = (initialPrice - discountedPrice) > 0 ? initialPrice - discountedPrice : 0;
        originalPrice = initialPrice;
        listingPrice = item.listingPrice > 0 ? item.listingPrice : item.currentPrice;
    }
    var usdtRate = 1;
    if (shoppingCartItem.subCartDetails && shoppingCartItem.subCartDetails.length > 0) {
        for (var _i = 0, _a = shoppingCartItem.subCartDetails; _i < _a.length; _i++) {
            var subCartDetail = _a[_i];
            // if (item.saleCurrency == Currency.USD) {
            //   usdtRate = usdtVNDRate.rate;
            // }
            var physicalItemPrice = 0;
            // if (subCartDetail)
            // physicalItemPrice = physicalItem.price * usdtRate;
            var totalVNDPrice_1 = physicalItemPrice * adjustCount;
            totalVNDOptionItemPrice = totalVNDOptionItemPrice + totalVNDPrice_1;
            // subCartDetail.price = physicalItemPrice;
            // subCartDetail.totalVNDPrice = totalVNDPrice;
        }
    }
    shoppingCartItem.price = itemPrice;
    var totalVNDPrice = itemPrice * adjustCount + totalVNDOptionItemPrice;
    var totalVNDItemPrice = itemPrice * adjustCount;
    var totalVNDListingPrice = listingPrice * adjustCount;
    var totalVNDSavingPrice = savingPrice * adjustCount;
    // const totalVNDOriginalPrice = originalPrice * adjustCount;
    shoppingCartItem.totalVNDPrice = totalVNDPrice;
    shoppingCartItem.totalVNDSavingPrice = totalVNDSavingPrice;
    shoppingCartItem.totalVNDOriginalPrice = totalVNDListingPrice + totalVNDOptionItemPrice;
    shoppingCartItem.totalVNDItemPrice = totalVNDItemPrice;
    shoppingCartItem.totalVNDOptionItemPrice = totalVNDOptionItemPrice;
    // orderPrice = orderPrice + totalVNDPrice;
    // orderVNDSavingPrice = orderVNDSavingPrice + totalVNDSavingPrice;
    // orderVNDOriginalPrice = orderVNDOriginalPrice + totalVNDOriginalPrice + totalVNDOptionItemPrice;
    return shoppingCartItem;
};
