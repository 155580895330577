import React from 'react';
import { useTranslation } from 'react-i18next';
import "./cart-tree.scss";
function CartTree(props) {
    var carts = props.carts, cartDesDetail = props.cartDesDetail;
    var t = useTranslation().t;
    return (React.createElement("div", { className: 'cart-tree-container w-full' }, carts && carts.length ? React.createElement("ul", { className: 'tree' }, carts.map(function (cart, index) { return React.createElement("li", { key: index },
        React.createElement("div", { className: 'tree_label w-full flex' },
            React.createElement("img", { className: 'w-[40px] h-[40px] rounded-md', src: cart.optionItem && cart.optionItem.image ? cart.optionItem.image : "", alt: "obranding-cart-image", width: "64", height: "64" }),
            React.createElement("div", { className: 'ml-3' },
                React.createElement("p", { className: 'title-physical title-sm my-1' }, "".concat(cart.optionItem && cart.optionItem.name ? cart.optionItem.name : "")),
                cartDesDetail ? React.createElement("p", { className: 'shopping-cart-text-default body-sm mt-1' }, "S\u1EA3n ph\u1EA9m v\u1EADt l\u00FD") : null))); })) : null));
}
export default CartTree;
