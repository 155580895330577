import { configureStore } from '@reduxjs/toolkit';
import CampaignReducer from './campaign/slice';
import NotificationReducer from './notification/slice';
import GlobalReducer from './global/slice';
import BrandReducer from './brands/slice';
import ShoppingCartReducer from './shopping-cart/slice';
export var store = configureStore({
    reducer: {
        campaigns: CampaignReducer,
        notifications: NotificationReducer,
        globals: GlobalReducer,
        brands: BrandReducer,
        shoppingCart: ShoppingCartReducer
    },
});
export default store;
