var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ethers } from "ethers";
import { CHAIN_ID, CONFIG_ADD_CHAIN, TOKEN_ABI } from "../config/blockchain";
import { decimalToHexString } from "../utils/CommonUtil";
import { userStorage } from "services";
var instance = null;
export var WalletType;
(function (WalletType) {
    WalletType["METAMASK"] = "METAMASK";
    WalletType["BITKEEP"] = "BITKEEP";
})(WalletType || (WalletType = {}));
var NetworkConnector = /** @class */ (function () {
    function NetworkConnector() {
        this.currentWallet = '';
        this.navigate = null;
        this.listenEventMetamask();
        this.listenEventBitkeep();
        this.checkConnectWallet();
    }
    NetworkConnector.prototype.getCurrentWallet = function () { return this.currentWallet; };
    NetworkConnector.getInstance = function () {
        if (instance)
            return instance;
        instance = new NetworkConnector();
        return instance;
    };
    NetworkConnector.prototype.setNavigate = function (func) {
        this.navigate = func;
    };
    NetworkConnector.prototype.waybackLogin = function () {
        console.log('---[waybackLogin]---');
        // removeAccessToken();
        // removeRefreshToken();
        // removeItem(USER_DATA_KEY);
        // if (this.navigate) this.navigate("/login");
    };
    NetworkConnector.prototype.listenEventMetamask = function () {
        var _this = this;
        console.log('listen event metamask');
        if (window && window.ethereum) {
            window.ethereum.on('chainChanged', function (networkId) {
                console.log('[metamask] chainChanged', networkId);
            });
            window.ethereum.on('accountsChanged', function (accounts) {
                console.log('[metamask] accountsChanged', accounts);
                if (!_this.currentWallet)
                    return;
                var userData = userStorage.get();
                if (accounts[0] && userData && (userData === null || userData === void 0 ? void 0 : userData.walletAddress) && accounts[0].toLowerCase() === (userData === null || userData === void 0 ? void 0 : userData.walletAddress.toLowerCase()))
                    return;
                if (window.document.getElementById('log-error')) {
                    window.document.getElementById('log-error').innerHTML = '[waybackLogin] accountsChanged,' + JSON.stringify(accounts);
                }
                _this.waybackLogin();
            });
            window.ethereum.on('networkChanged', function (networkId) { return __awaiter(_this, void 0, void 0, function () {
                var changed;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            console.log("[metamask] networkChanged", networkId);
                            if (!this.currentWallet)
                                return [2 /*return*/];
                            if (!(networkId != CHAIN_ID)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.changeNetworkMetamask(CHAIN_ID)];
                        case 1:
                            changed = _a.sent();
                            console.log('[metamask] user changed', changed);
                            if (window.document.getElementById('log-error')) {
                                window.document.getElementById('log-error').innerHTML = "[waybackLogin] networkChanged, ".concat(networkId, " (").concat(typeof networkId, "), ").concat(CHAIN_ID, " (").concat(typeof CHAIN_ID, "), user changed ").concat(changed);
                            }
                            if (!changed)
                                this.waybackLogin();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    NetworkConnector.prototype.listenEventBitkeep = function () {
        var _this = this;
        console.log('listen event bitkeep');
        if (window && window.bitkeep && window.bitkeep.ethereum) {
            window.bitkeep.ethereum.on('chainChanged', function (networkId) {
                console.log('[bitkeep] chainChanged', networkId);
            });
            window.bitkeep.ethereum.on('accountsChanged', function (accounts) {
                console.log('[bitkeep] accountsChanged', accounts);
                if (!_this.currentWallet)
                    return;
                _this.waybackLogin();
            });
            window.bitkeep.ethereum.on('networkChanged', function (networkId) { return __awaiter(_this, void 0, void 0, function () {
                var changed;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            console.log("[bitkeep] networkChanged", networkId);
                            if (!this.currentWallet)
                                return [2 /*return*/];
                            if (!(networkId != CHAIN_ID)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.changeNetworkBitkeep(CHAIN_ID)];
                        case 1:
                            changed = _a.sent();
                            console.log('[bitkeep] user changed', changed);
                            if (!changed)
                                this.waybackLogin();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    NetworkConnector.prototype.disconnectWallet = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                console.log('disconnect wallet', this.currentWallet);
                this.currentWallet = '';
                return [2 /*return*/];
            });
        });
    };
    NetworkConnector.prototype.checkConnectWallet = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var userData, provider, signer, address, chainId, error_1, providerBitkeep, signer, address, chainId, error_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        userData = userStorage.get();
                        if (!userData) return [3 /*break*/, 13];
                        if (!(window && window.ethereum)) return [3 /*break*/, 6];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 5, , 6]);
                        provider = new ethers.providers.Web3Provider(window.ethereum);
                        signer = provider.getSigner();
                        return [4 /*yield*/, signer.getAddress()];
                    case 2:
                        address = _c.sent();
                        console.log('address metamask', address);
                        if (!(address && address.toLowerCase() === ((_a = userData === null || userData === void 0 ? void 0 : userData.walletAddress) === null || _a === void 0 ? void 0 : _a.toLowerCase()))) return [3 /*break*/, 4];
                        return [4 /*yield*/, provider.getNetwork()];
                    case 3:
                        chainId = (_c.sent()).chainId;
                        if (chainId != CHAIN_ID) {
                            if (window.document.getElementById('log-error')) {
                                window.document.getElementById('log-error').innerHTML = "[waybackLogin] checkConnectWallet, chainId: ".concat(chainId, " (").concat(typeof chainId, "), CHAIN_ID: ").concat(CHAIN_ID, " (").concat(typeof CHAIN_ID, ")");
                            }
                            return [2 /*return*/, this.waybackLogin()];
                        }
                        this.currentWallet = WalletType.METAMASK;
                        console.log('Connect', this.currentWallet);
                        return [2 /*return*/];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _c.sent();
                        if (window.document.getElementById('log-error')) {
                            window.document.getElementById('log-error').innerHTML = "[waybackLogin] checkConnectWallet metamask fail";
                        }
                        console.log('checkConnectWallet metamask fail', error_1);
                        return [3 /*break*/, 6];
                    case 6:
                        if (!(window && window.bitkeep && window.bitkeep.ethereum)) return [3 /*break*/, 12];
                        _c.label = 7;
                    case 7:
                        _c.trys.push([7, 11, , 12]);
                        providerBitkeep = new ethers.providers.Web3Provider(window.bitkeep.ethereum);
                        signer = providerBitkeep.getSigner();
                        return [4 /*yield*/, signer.getAddress()];
                    case 8:
                        address = _c.sent();
                        console.log('address bitkeep', address);
                        if (!(address && address.toLowerCase() === ((_b = userData === null || userData === void 0 ? void 0 : userData.walletAddress) === null || _b === void 0 ? void 0 : _b.toLowerCase()))) return [3 /*break*/, 10];
                        return [4 /*yield*/, providerBitkeep.getNetwork()];
                    case 9:
                        chainId = (_c.sent()).chainId;
                        if (chainId != CHAIN_ID)
                            return [2 /*return*/, this.waybackLogin()];
                        this.currentWallet = WalletType.BITKEEP;
                        console.log('Connect', this.currentWallet);
                        return [2 /*return*/];
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        error_2 = _c.sent();
                        console.log('checkConnectWallet bitkeep fail', error_2);
                        return [3 /*break*/, 12];
                    case 12:
                        if (window.document.getElementById('log-error')) {
                            window.document.getElementById('log-error').innerHTML = "[waybackLogin] no environment";
                        }
                        this.waybackLogin();
                        _c.label = 13;
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    NetworkConnector.prototype.signInBitkeep = function () {
        return __awaiter(this, void 0, void 0, function () {
            var provider, chainId, signer, address;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, window.bitkeep.ethereum.send("eth_requestAccounts")];
                    case 1:
                        _a.sent();
                        provider = new ethers.providers.Web3Provider(window.bitkeep.ethereum);
                        return [4 /*yield*/, provider.getNetwork()];
                    case 2:
                        chainId = (_a.sent()).chainId;
                        signer = provider.getSigner();
                        return [4 /*yield*/, signer.getAddress()];
                    case 3:
                        address = _a.sent();
                        this.currentWallet = WalletType.BITKEEP;
                        return [2 /*return*/, {
                                chainId: chainId,
                                signer: signer,
                                address: address
                            }];
                }
            });
        });
    };
    NetworkConnector.prototype.signInMetamask = function () {
        return __awaiter(this, void 0, void 0, function () {
            var provider, chainId, signer, address;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, window.ethereum.send("eth_requestAccounts")];
                    case 1:
                        _a.sent();
                        provider = new ethers.providers.Web3Provider(window.ethereum);
                        return [4 /*yield*/, provider.getNetwork()];
                    case 2:
                        chainId = (_a.sent()).chainId;
                        signer = provider.getSigner();
                        return [4 /*yield*/, signer.getAddress()];
                    case 3:
                        address = _a.sent();
                        this.currentWallet = WalletType.METAMASK;
                        return [2 /*return*/, {
                                chainId: chainId,
                                signer: signer,
                                address: address,
                            }];
                }
            });
        });
    };
    NetworkConnector.prototype.changeNetworkMetamask = function (chainId, tried) {
        if (tried === void 0) { tried = false; }
        return __awaiter(this, void 0, void 0, function () {
            var hex, error_3, addError_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('changeNetworkMetamask', chainId, tried);
                        hex = decimalToHexString(chainId);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 8]);
                        return [4 /*yield*/, window.ethereum.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: hex }], // chainId must be in hexadecimal numbers
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 3:
                        error_3 = _a.sent();
                        console.log('[metamask] Error request chain: ', error_3);
                        if (!(error_3.message && error_3.message.indexOf('wallet_addEthereumChain') > -1 && !tried)) return [3 /*break*/, 7];
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        console.log('[metamask] try add chain', error_3.message);
                        return [4 /*yield*/, window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [CONFIG_ADD_CHAIN[hex]],
                            })];
                    case 5:
                        _a.sent();
                        this.changeNetworkMetamask(chainId, true);
                        return [3 /*break*/, 7];
                    case 6:
                        addError_1 = _a.sent();
                        console.log('[metamask] addError', addError_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/, false];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    NetworkConnector.prototype.changeNetworkBitkeep = function (chainId, tried) {
        if (tried === void 0) { tried = false; }
        return __awaiter(this, void 0, void 0, function () {
            var hex, error_4, addError_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('changeNetworkBitkeep', chainId, tried);
                        hex = decimalToHexString(chainId);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 8]);
                        return [4 /*yield*/, window.bitkeep.ethereum.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: hex }], // chainId must be in hexadecimal numbers
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 3:
                        error_4 = _a.sent();
                        console.log('[bitkeep] Error request chain: ', error_4);
                        if (!(error_4.message && error_4.message.indexOf('wallet_addEthereumChain') > -1 && !tried)) return [3 /*break*/, 7];
                        _a.label = 4;
                    case 4:
                        _a.trys.push([4, 6, , 7]);
                        console.log('[bitkeep] try add chain');
                        return [4 /*yield*/, window.bitkeep.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [CONFIG_ADD_CHAIN[hex]],
                            })];
                    case 5:
                        _a.sent();
                        return [2 /*return*/, this.changeNetworkBitkeep(chainId, true)];
                    case 6:
                        addError_2 = _a.sent();
                        console.log('[bitkeep] addError', addError_2);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/, false];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    // TODO: request exactly wallet have token
    NetworkConnector.prototype.checkOPVBalance = function (tokenAddress, amount) {
        return __awaiter(this, void 0, void 0, function () {
            var provider, signer, address, tokenContract, decimal, balance;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        provider = new ethers.providers.Web3Provider(this.currentWallet === WalletType.BITKEEP ? window.bitkeep.ethereum : window.ethereum);
                        signer = provider.getSigner();
                        return [4 /*yield*/, signer.getAddress()];
                    case 1:
                        address = _a.sent();
                        tokenContract = new ethers.Contract(tokenAddress, TOKEN_ABI, signer);
                        return [4 /*yield*/, tokenContract.decimals()];
                    case 2:
                        decimal = _a.sent();
                        return [4 /*yield*/, tokenContract.balanceOf(address)];
                    case 3:
                        balance = _a.sent();
                        console.log("balance", balance);
                        console.log("amount", amount);
                        return [2 /*return*/, balance.gte(ethers.utils.parseUnits(String(amount), decimal))];
                }
            });
        });
    };
    NetworkConnector.prototype.sendOPV = function (tokenAddress, receiver, amount) {
        return __awaiter(this, void 0, void 0, function () {
            var provider, signer, address, balance, tokenContract, decimal, result, estimatedGas, tx;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        provider = new ethers.providers.Web3Provider(this.currentWallet === WalletType.BITKEEP ? window.bitkeep.ethereum : window.ethereum);
                        signer = provider.getSigner();
                        return [4 /*yield*/, signer.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, provider.getBalance(address)];
                    case 2:
                        balance = _a.sent();
                        tokenContract = new ethers.Contract(tokenAddress, TOKEN_ABI, signer);
                        return [4 /*yield*/, tokenContract.decimals()];
                    case 3:
                        decimal = _a.sent();
                        result = {
                            amount: ethers.utils.parseUnits(String(amount), decimal)
                        };
                        return [4 /*yield*/, tokenContract.estimateGas.transfer(receiver, ethers.utils.parseUnits(String(amount), decimal))];
                    case 4:
                        estimatedGas = _a.sent();
                        if (!balance.gte(estimatedGas)) return [3 /*break*/, 6];
                        return [4 /*yield*/, tokenContract.transfer(receiver, ethers.utils.parseUnits(String(amount), decimal))];
                    case 5:
                        tx = _a.sent();
                        result.hash = tx.hash;
                        _a.label = 6;
                    case 6: return [2 /*return*/, result];
                }
            });
        });
    };
    NetworkConnector.prototype.signPayment = function (contactAddress, transactionId, amount, orderId, order) {
        return __awaiter(this, void 0, void 0, function () {
            var SIGNING_DOMAIN_NAME, SIGNING_DOMAIN_VERSION, provider, signer, address, domain, signData, types, signature, signPaymentBody;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('signPayment', contactAddress, transactionId, amount, orderId);
                        SIGNING_DOMAIN_NAME = "openlive-payment";
                        SIGNING_DOMAIN_VERSION = "9";
                        provider = new ethers.providers.Web3Provider(this.currentWallet === WalletType.BITKEEP ? window.bitkeep.ethereum : window.ethereum);
                        signer = provider.getSigner();
                        return [4 /*yield*/, signer.getAddress()];
                    case 1:
                        address = _a.sent();
                        domain = {
                            name: SIGNING_DOMAIN_NAME,
                            version: SIGNING_DOMAIN_VERSION,
                            verifyingContract: contactAddress,
                            chainId: CHAIN_ID // testnet 97, mainnet 1
                        };
                        signData = {
                            transactionId: transactionId,
                            orderId: orderId,
                            amount: amount,
                            buyer: address,
                        };
                        types = {
                            OrderPayment: [
                                { name: "transactionId", type: "string" },
                                { name: "orderId", type: "string" },
                                { name: "amount", type: "uint256" },
                                { name: "buyer", type: "address" }
                            ]
                        };
                        return [4 /*yield*/, signer._signTypedData(domain, types, signData)];
                    case 2:
                        signature = _a.sent();
                        signPaymentBody = [signData.transactionId, signData.orderId, signData.amount, signData.buyer, signature];
                        if (order && order.id) {
                            signPaymentBody.push(order.id);
                        }
                        if (order && signPaymentBody.length > 4 && order.orderCode) {
                            signPaymentBody.push(order.orderCode);
                        }
                        return [2 /*return*/, signPaymentBody];
                }
            });
        });
    };
    return NetworkConnector;
}());
export default NetworkConnector.getInstance();
