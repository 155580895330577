var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignUpRoute from "./SignupRoute";
import routes from "./Routes";
import ActiveUserRoute from "./ActiveUserRoute";
import LoginRoute from "./LoginRoute";
import ForgotPasswordRoute from "./ForgotPasswordRoute";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
var RouterRoot = function () {
    return (React.createElement(Router, null,
        React.createElement(Routes, null,
            React.createElement(Route, { element: React.createElement(SignUpRoute, null) },
                React.createElement(Route, __assign({}, routes.signUp))),
            React.createElement(Route, { element: React.createElement(ActiveUserRoute, null) },
                React.createElement(Route, __assign({}, routes.activeUser))),
            React.createElement(Route, { element: React.createElement(LoginRoute, null) },
                React.createElement(Route, __assign({}, routes.login))),
            React.createElement(Route, { element: React.createElement(ForgotPasswordRoute, null) },
                React.createElement(Route, __assign({}, routes.forgotPassword))),
            React.createElement(Route, { element: React.createElement(PublicRoute, null) },
                React.createElement(Route, __assign({}, routes.home)),
                React.createElement(Route, __assign({}, routes.fan)),
                React.createElement(Route, __assign({}, routes.fanDetail)),
                React.createElement(Route, __assign({}, routes.membership)),
                React.createElement(Route, __assign({}, routes.membershipDetail)),
                React.createElement(Route, __assign({}, routes.vouchers)),
                React.createElement(Route, __assign({}, routes.voucherDetails)),
                React.createElement(Route, __assign({}, routes.searchResult)),
                React.createElement(Route, __assign({}, routes.brands)),
                React.createElement(Route, __assign({}, routes.brandDetails)),
                React.createElement(Route, __assign({}, routes.collectionDetail)),
                React.createElement(Route, __assign({}, routes.collectionDetailWhiteList)),
                React.createElement(Route, __assign({}, routes.detailScanQR)),
                React.createElement(Route, __assign({}, routes.digitalProduct)),
                React.createElement(Route, __assign({}, routes.obrandingCard)),
                React.createElement(Route, __assign({}, routes.obrandingPartner)),
                React.createElement(Route, __assign({}, routes.obrandingAllPartners)),
                React.createElement(Route, __assign({}, routes.aboutLoyalty)),
                React.createElement(Route, __assign({}, routes.faq)),
                React.createElement(Route, __assign({}, routes.termPayment)),
                React.createElement(Route, __assign({}, routes.termUesr)),
                React.createElement(Route, __assign({}, routes.termSecure)),
                React.createElement(Route, __assign({}, routes.disputeProcess)),
                React.createElement(Route, __assign({}, routes.termUserCondition)),
                React.createElement(Route, __assign({}, routes.paymentGuide)),
                React.createElement(Route, __assign({}, routes.paymentRoyalty)),
                React.createElement(Route, __assign({}, routes.cartDetail)),
                React.createElement(Route, __assign({}, routes.notFoundPage)),
                React.createElement(Route, __assign({}, routes.nfcInformation))),
            React.createElement(Route, { element: React.createElement(PrivateRoute, null) },
                React.createElement(Route, __assign({}, routes.affiliateRegistration)),
                React.createElement(Route, __assign({}, routes.brandAdminType)),
                React.createElement(Route, __assign({}, routes.brandAdminTypeDetail)),
                React.createElement(Route, __assign({}, routes.brandAdminTypeDetailAction)),
                React.createElement(Route, __assign({}, routes.brandAdminTypeAction)),
                React.createElement(Route, __assign({}, routes.myBrandAdminType)),
                React.createElement(Route, __assign({}, routes.myBrandAdminTypeDetailAction)),
                React.createElement(Route, __assign({}, routes.masterAdminType)),
                React.createElement(Route, __assign({}, routes.masterAdminTypeDetailAction)),
                React.createElement(Route, __assign({}, routes.masterAdminTypeAction)),
                React.createElement(Route, __assign({}, routes.masterAdminOrigin)),
                React.createElement(Route, __assign({}, routes.affiliateType)),
                React.createElement(Route, __assign({}, routes.affiliateTypeDetailAction)),
                React.createElement(Route, __assign({}, routes.affiliateTypeAction)),
                React.createElement(Route, __assign({}, routes.affiliateOrigin))))));
};
export default RouterRoot;
