var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as cartRepository from "api/cart-reponsitory";
import store from "../store";
import { setDataShoppingCart } from "../store/shopping-cart/slice";
import { handleResponse } from "../utils/ApiResponse";
import { getItem } from "../utils/LocalStorageUtil";
import { TOKEN_KEY } from "../utils/StorageKeys";
export var saveProductToCookie = function (data, type, cbCheckInvalid) {
    var products = getProductsFromCookie();
    var existingProduct = products.find(function (product) {
        return product.itemId === data.itemId && product.itemType === data.itemType;
    });
    if (existingProduct) {
        if (type === "adjust") {
            if (!data.numOfItem) {
                products = products.filter(function (product) {
                    return product.itemId !== existingProduct.itemId ||
                        product.itemType !== existingProduct.itemType;
                });
            }
            else {
                existingProduct.numOfItem = data.numOfItem;
            }
        }
        else if (type === "add") {
            if (data.productOptionId && existingProduct.productOptionId && data.productOptionId != existingProduct.productOptionId) {
                cbCheckInvalid();
            }
            else {
                existingProduct.numOfItem += data.numOfItem;
            }
        }
        else {
            products = products.filter(function (product) {
                return product.itemId !== existingProduct.itemId ||
                    product.itemType !== existingProduct.itemType;
            });
        }
    }
    else {
        products.push(data);
    }
    document.cookie = "products=".concat(JSON.stringify(products), "; expires=").concat(getCookieExpiration(365), "; path=/;");
};
export var clearProductToCookie = function () {
    document.cookie = "products=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};
export var getProductsFromCookie = function () {
    var cookies = document.cookie.split(";");
    var productCookie = cookies.find(function (cookie) {
        return cookie.trim().startsWith("products=");
    });
    if (productCookie) {
        var productsString = productCookie.split("=")[1];
        return JSON.parse(productsString);
    }
    return [];
};
var getCookieExpiration = function (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    return date.toUTCString();
};
export var getShoppingCartView = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, cartRepository.getShoppingCartView()];
            case 1:
                response = _a.sent();
                if (response.data) {
                    store.dispatch(setDataShoppingCart({ data: response.data }));
                }
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, Promise.reject(error_1)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getShoppingCartDetailView = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, cartRepository.getShoppingCartView()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, Promise.reject(error_2)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var addItemShoppingCartView = function (body, cbCheckInvalid) { return __awaiter(void 0, void 0, void 0, function () {
    var token, isValid_1, result, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                return [4 /*yield*/, getItem(TOKEN_KEY)];
            case 1:
                token = _a.sent();
                if (!!token) return [3 /*break*/, 3];
                isValid_1 = true;
                if (!body.numOfItem) {
                    body.numOfItem = 1;
                }
                saveProductToCookie(body, "add", function () {
                    isValid_1 = false;
                });
                if (!isValid_1 && cbCheckInvalid) {
                    cbCheckInvalid();
                    return [2 /*return*/];
                }
                return [4 /*yield*/, cartRepository.getCartTemp(getProductsFromCookie())];
            case 2:
                result = _a.sent();
                if (result && result.data) {
                    store.dispatch(setDataShoppingCart({ data: result.data }));
                }
                return [2 /*return*/, handleResponse(result)];
            case 3: return [4 /*yield*/, cartRepository.addItemShoppingCartView(body)];
            case 4:
                response = _a.sent();
                if (response.data) {
                    store.dispatch(setDataShoppingCart({ data: response.data }));
                }
                return [2 /*return*/, handleResponse(response)];
            case 5: return [3 /*break*/, 7];
            case 6:
                error_3 = _a.sent();
                return [2 /*return*/, Promise.reject(error_3)];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var deleteItemShoppingCartView = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var token, result, response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                return [4 /*yield*/, getItem(TOKEN_KEY)];
            case 1:
                token = _a.sent();
                if (!!token) return [3 /*break*/, 3];
                saveProductToCookie(body, "delete", null);
                return [4 /*yield*/, cartRepository.getCartTemp(getProductsFromCookie())];
            case 2:
                result = _a.sent();
                if (result && result.data) {
                    store.dispatch(setDataShoppingCart({ data: result.data }));
                }
                return [2 /*return*/, handleResponse(result)];
            case 3: return [4 /*yield*/, cartRepository.deleteItemShoppingCartView(body)];
            case 4:
                response = _a.sent();
                if (response.data) {
                    store.dispatch(setDataShoppingCart({ data: response.data }));
                }
                return [2 /*return*/, handleResponse(response)];
            case 5: return [3 /*break*/, 7];
            case 6:
                error_4 = _a.sent();
                return [2 /*return*/, Promise.reject(error_4)];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var adjustItemShoppingCartView = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var token, result, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                return [4 /*yield*/, getItem(TOKEN_KEY)];
            case 1:
                token = _a.sent();
                if (!!token) return [3 /*break*/, 3];
                saveProductToCookie(body, "adjust", null);
                return [4 /*yield*/, cartRepository.getCartTemp(getProductsFromCookie())];
            case 2:
                result = _a.sent();
                if (result && result.data) {
                    store.dispatch(setDataShoppingCart({ data: result.data }));
                }
                return [2 /*return*/, handleResponse(result)];
            case 3: return [4 /*yield*/, cartRepository.adjustItemShoppingCartView(body)];
            case 4:
                response = _a.sent();
                if (response.data) {
                    store.dispatch(setDataShoppingCart({ data: response.data }));
                }
                return [2 /*return*/, handleResponse(response)];
            case 5: return [3 /*break*/, 7];
            case 6:
                error_5 = _a.sent();
                return [2 /*return*/, Promise.reject(error_5)];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var syncShoppingCartView = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var token, response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, getItem(TOKEN_KEY)];
            case 1:
                token = _a.sent();
                if (!token) return [3 /*break*/, 3];
                return [4 /*yield*/, cartRepository.syncShoppingCartView(body)];
            case 2:
                response = _a.sent();
                if (response.data) {
                    store.dispatch(setDataShoppingCart({ data: response.data }));
                }
                return [2 /*return*/, handleResponse(response)];
            case 3: return [3 /*break*/, 5];
            case 4:
                error_6 = _a.sent();
                return [2 /*return*/, Promise.reject(error_6)];
            case 5: return [2 /*return*/];
        }
    });
}); };
export var shoppingUnlockCartView = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, cartRepository.shoppingUnlockCartView()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_7 = _a.sent();
                return [2 /*return*/, Promise.reject(error_7)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var mergeLocalAndUserCart = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, cartRepository.mergeLocalAndUserCart(body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_8 = _a.sent();
                return [2 /*return*/, Promise.reject(error_8)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var saveStoreShoppingCart = function (shoppingCartData) {
    if (shoppingCartData) {
        store.dispatch(setDataShoppingCart({ data: shoppingCartData }));
    }
};
