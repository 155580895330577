import storage from "lib/storage";
import { USER_DATA_KEY } from "utils/StorageKeys";
import { useStorage } from "hooks";
var User = {
    get: function () {
        return storage.get(USER_DATA_KEY);
    },
    set: function (value) { return storage.set(USER_DATA_KEY, value); },
    update: function (value) {
        var userInfo = User.get();
        if (!userInfo)
            return;
        if (value) {
            if (typeof value === "function") {
                var newData = value(userInfo);
                storage.update(USER_DATA_KEY, newData);
                return;
            }
            storage.update(USER_DATA_KEY, value);
        }
    },
    useInfo: function () { return useStorage(USER_DATA_KEY); },
    remove: function () {
        storage.remove(USER_DATA_KEY);
    },
    logout: function () { },
};
export default User;
