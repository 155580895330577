import { useCallback, useEffect, useState } from "react";
import storage from "lib/storage";
import useEventListener from "./useEventListener";
var useReadStorage = function (key) {
    // Get from local storage then
    // parse stored json or return initialValue
    var readValue = useCallback(function () {
        // Prevent build error "window is undefined" but keep keep working
        if (typeof window === "undefined") {
            return null;
        }
        try {
            return storage.get(key);
        }
        catch (error) {
            console.warn("Error reading localStorage key \u201C".concat(key, "\u201D:"), error);
            return null;
        }
    }, [key]);
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    var _a = useState(readValue), storedValue = _a[0], setStoredValue = _a[1];
    // Listen if localStorage changes
    useEffect(function () {
        setStoredValue(readValue());
    }, []);
    var handleStorageChange = useCallback(function (event) {
        if ((event === null || event === void 0 ? void 0 : event.key) && event.key !== key) {
            return;
        }
        setStoredValue(readValue());
    }, [key, readValue]);
    useEventStorage(key, handleStorageChange);
    return storedValue;
};
var useEventStorage = function (key, callback) {
    useEventListener("local-storage-".concat(key), callback);
};
export default useReadStorage;
