import { createSlice } from '@reduxjs/toolkit';
import { fetchGlobalNotifications } from './thunks';
import { STATUS_LOADING, STATUS_SUCCEEDED, STATUS_FAILED } from '../../utils/Constants';
var initialState = {
    data: null,
    status: "idle",
    error: ""
};
var notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {
        setNotificationGlobalInfo: function (state, _a) {
            var data = _a.payload.data;
            state.data = data;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(fetchGlobalNotifications.pending, function (state, action) {
            state.status = STATUS_LOADING;
        })
            .addCase(fetchGlobalNotifications.fulfilled, function (state, action) {
            state.status = STATUS_SUCCEEDED;
            state.data = action.payload;
        })
            .addCase(fetchGlobalNotifications.rejected, function (state, action) {
            state.status = STATUS_FAILED;
            state.error = action && action.error && action.error.message ? action.error.message : "";
        });
    }
});
export var setNotificationGlobalInfo = notificationsSlice.actions.setNotificationGlobalInfo;
//Fetch Notifications
export var selectAllNotifications = function (state) { return state.notifications ? state.notifications.data : null; };
export var getNotificationsError = function (state) { return state.notifications ? state.notifications.error : ""; };
export var getNotificationsStatus = function (state) { return state.notifications ? state.notifications.status : ""; };
export default notificationsSlice.reducer;
