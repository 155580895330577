var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiClient } from "api";
import { handleResponse } from "utils/ApiResponse";
var authUrl = "auth";
var userUrl = "user";
export var requestLoginWallet = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(authUrl, "/request-login"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, Promise.reject(error_1)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var loginWallet = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(authUrl, "/login"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, Promise.reject(error_2)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var connectWallet = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(userUrl, "/sync-wallet"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_3 = _a.sent();
                return [2 /*return*/, Promise.reject(error_3)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var logoutWallet = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(authUrl, "/logout"))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_4 = _a.sent();
                return [2 /*return*/, Promise.reject(error_4)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var requestToken = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(authUrl, "/requesttoken"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_5 = _a.sent();
                return [2 /*return*/, Promise.reject(error_5)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getUserInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("/me")];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_6 = _a.sent();
                return [2 /*return*/, Promise.reject(error_6)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var signUpAccountByEmail = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/signup-by-email"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_7 = _a.sent();
                return [2 /*return*/, Promise.reject(error_7)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var signUpAccountByPhone = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/signup-by-phone"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_8 = _a.sent();
                return [2 /*return*/, Promise.reject(error_8)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var verifyAccountByEmail = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("/".concat(userUrl, "/verify-email?token=").concat(token))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_9 = _a.sent();
                return [2 /*return*/, Promise.reject(error_9)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var verifyAccountByPhone = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/verify-phone"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_10 = _a.sent();
                return [2 /*return*/, Promise.reject(error_10)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var verifyAccountByOTPEmail = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/verify-email-signup"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_11 = _a.sent();
                return [2 /*return*/, Promise.reject(error_11)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var userLogin = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/loginByEmailOrPhone"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_12 = _a.sent();
                return [2 /*return*/, Promise.reject(error_12)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var verify2FA = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/verify-otp"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_13 = _a.sent();
                return [2 /*return*/, Promise.reject(error_13)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var forgetPasswordByEmail = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/send-otp-forget-password-email"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_14 = _a.sent();
                return [2 /*return*/, Promise.reject(error_14)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var forgetPasswordByPhone = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/send-otp-forget-password-phone"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_15 = _a.sent();
                return [2 /*return*/, Promise.reject(error_15)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var sendOtpVerify2Fa = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/send-otp-verify-2fa"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_16 = _a.sent();
                return [2 /*return*/, Promise.reject(error_16)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var verifyForgetPassword = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/".concat(userUrl, "/verify-forget-password"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_17 = _a.sent();
                return [2 /*return*/, Promise.reject(error_17)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var changePassword = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(userUrl, "/change-password-service"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_18 = _a.sent();
                return [2 /*return*/, Promise.reject(error_18)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var updateProfile = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(userUrl, "/edit-user-profile"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_19 = _a.sent();
                return [2 /*return*/, Promise.reject(error_19)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getUserProfile = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_20;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(userUrl, "/get-user-profile"))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_20 = _a.sent();
                return [2 /*return*/, Promise.reject(error_20)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var updateTwoFactoryAuthentication = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_21;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(userUrl, "/enable2fa"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_21 = _a.sent();
                return [2 /*return*/, Promise.reject(error_21)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var resendVerifyEmail = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_22;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(userUrl, "/resend-verify-email"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_22 = _a.sent();
                return [2 /*return*/, Promise.reject(error_22)];
            case 3: return [2 /*return*/];
        }
    });
}); };
