/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import "./count-input.scss";
import { notificationSimple } from "utils/CommonUtil";
var CountInput = function (_a) {
    var numOfItem = _a.numOfItem, onChangeCart = _a.onChangeCart, activeInputFocus = _a.activeInputFocus, id = _a.id, handleChangeActiveFocus = _a.handleChangeActiveFocus, activeInputFocusType = _a.activeInputFocusType, handleChangeActiveFocusType = _a.handleChangeActiveFocusType, remaining = _a.remaining;
    var _b = useState(numOfItem), count = _b[0], setCount = _b[1];
    var inputCountRef = useRef(null);
    var timeoutRef = useRef(null);
    var t = useTranslation().t;
    useEffect(function () {
        // setCount(numOfItem);
        if (id && activeInputFocus && activeInputFocusType && activeInputFocusType === "CHANGE" && activeInputFocus.id && activeInputFocus.id === id && inputCountRef && inputCountRef.current) {
            inputCountRef.current.focus();
        }
    }, [numOfItem]);
    var debouncedChangeCart = useCallback(debounce(function (newCount) {
        // onChangeCart(newCount);
        if (handleChangeActiveFocusType) {
            handleChangeActiveFocusType("CHANGE");
        }
    }, 300), // Adjust the debounce delay as needed
    [onChangeCart]);
    var onEventAdjustCart = function (num) {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(function () {
            onChangeCart(num);
            if (handleChangeActiveFocus) {
                handleChangeActiveFocus(true);
            }
        }, 500);
    };
    var onChangeCount = function (type, event) {
        var num = count;
        switch (type) {
            case "ADD":
                num += 1;
                if (remaining && num > remaining) {
                    num = remaining;
                    notificationSimple(t("error.remaining_not_enough"), "NOTIFICATION_WARN");
                }
                setCount(num);
                onChangeCart(num);
                break;
            case "MINUS":
                num -= 1;
                if (num <= 0) {
                    num = 1;
                }
                setCount(num);
                onChangeCart(num);
                break;
            case "CHANGE":
                var value = event.currentTarget.value;
                num = Number(value);
                if (remaining && num > remaining) {
                    num = remaining;
                    notificationSimple(t("error.remaining_not_enough"), "NOTIFICATION_WARN");
                }
                if (value != "") {
                    setCount(num);
                    // debouncedChangeCart(Number(num));
                }
                else {
                    setCount(value);
                }
                break;
        }
    };
    var onNumberChange = function (event, type) {
        var value = event.currentTarget.value;
        if (value <= 0) {
            setCount(1);
        }
        // debouncedChangeCart(Number(value));
        onChangeCart(Number(value));
    };
    var onKeyDown = function (event) {
        if (event.key === "Enter") {
            var value = event.currentTarget.value;
            if (value <= 0) {
                setCount(1);
            }
            onChangeCart(Number(value));
            // debouncedChangeCart(Number(value));
        }
    };
    return (React.createElement("div", { className: "relative flex justify-center" },
        React.createElement("div", { className: "absolute inset-y-0 left-0 flex items-center" },
            React.createElement(MinusCircleIcon, { className: "count-input-minus", width: "40px", height: "40px", fill: "#DE6436", onClick: function (event) { return onChangeCount("MINUS", event); } })),
        React.createElement("input", { type: "number", ref: inputCountRef, value: count, onBlur: function (event) { return onNumberChange(event, "BLUR"); }, onKeyDown: function (event) { return onKeyDown(event); }, onChange: function (event) { return onChangeCount("CHANGE", event); }, onFocus: function (event) {
                if (handleChangeActiveFocus) {
                    handleChangeActiveFocus();
                }
                // if (inputCountRef && inputCountRef.current){
                //   inputCountRef.current.focus();
                // }
            }, className: "count-input bg-field border-0 block py-2 pl-10 pr-10 text-center text-sm text-gray-900 border-gray-300 rounded-full focus:ring-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500", placeholder: "", required: true }),
        React.createElement("div", { className: "absolute inset-y-0 right-0 flex items-center" },
            React.createElement(PlusCircleIcon, { className: "count-input-plus", width: "40px", height: "40px", fill: "#DE6436", onClick: function (event) { return onChangeCount("ADD", event); } }))));
};
export default CountInput;
