var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useRef, useState } from "react";
import './index.scss';
import { MyContext } from "../../../header-bar/Header";
import ScannerQR from "../../../common/scannerQR";
import { scanQRMembership } from "api/membership-repository";
import { useNavigate } from "react-router-dom";
import { notificationSimple } from "utils/CommonUtil";
import { useTranslation } from "react-i18next";
import * as Constant from 'utils/Constants';
import moment from "moment";
var ModalScannerQrCode = function (props) {
    var setOpenModalScannerQRCode = useContext(MyContext).setOpenModalScannerQRCode;
    var modalMemoRef = useRef(null);
    var t = useTranslation().t;
    var navigate = useNavigate();
    var _a = useState(window.innerWidth), width = _a[0], setWidth = _a[1];
    var handleResult = function (result) { return __awaiter(void 0, void 0, void 0, function () {
        var time, data, error_1, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!result) return [3 /*break*/, 2];
                    time = moment().lang("vi").format("YYYYMMDDHH");
                    return [4 /*yield*/, scanQRMembership({ c: result, t: time })];
                case 1:
                    data = _a.sent();
                    if (data && data.isValid && data.data && data.data.productOwnerBenefits.length) {
                        navigate("/detail-scan-qr?code=".concat(result));
                    }
                    else {
                        notificationSimple(t("notification.scan_code_invalid"), Constant.NOTIFICATION_ERROR);
                    }
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    message = error_1 && error_1.error && error_1.error.message ? error_1.error.message : t("error.general");
                    notificationSimple(t(message), Constant.NOTIFICATION_ERROR);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var useOutsidePopUp = function (ref) {
        useEffect(function () {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenModalScannerQRCode(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return function () {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    useOutsidePopUp(modalMemoRef);
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(function () {
        window.addEventListener('resize', handleWindowSizeChange);
        return function () {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    return width <= 768 ?
        React.createElement("div", { className: "modal-create-campaign modal-scan-qr modal fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto", id: "exampleModalCenteredScrollable", tabIndex: -1, "aria-labelledby": "exampleModalCenteredScrollable", "aria-modal": "true", role: "dialog" },
            React.createElement("div", { className: "modal-dialog modal-dialog-centered modal-dialog-scrollable relative w-auto pointer-events-none justify-center" },
                React.createElement("div", { ref: modalMemoRef, className: "modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-[#272727] bg-clip-padding rounded-md outline-none text-current" },
                    React.createElement("div", { className: "flex justify-center items-center p-5 pb-0 pt-16 rounded-t mb-0" },
                        React.createElement("h4", { className: "text-xl font-sans font-semibold my-5 text-white" }, "Qu\u00E9t m\u00E3 QR")),
                    React.createElement("div", { className: "m-6 mt-0 mb-0" },
                        React.createElement(ScannerQR, { handleResult: handleResult, isScanProduct: true })),
                    React.createElement("div", { className: "text-white text-lg mt-1 text-center m-auto mb-14" },
                        React.createElement("p", { className: "w-[200px] my-0" }, "Vui l\u00F2ng \u0111\u1EC3 m\u00E3 QR v\u00E0o gi\u1EEFa camera")),
                    React.createElement("div", { className: "modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md" },
                        React.createElement("button", { type: "button", className: "button-l w-full load-more-btn text-white inline-block px-8 py-2 font-medium text-xs leading-tight rounded-full shadow-md transition duration-150 ease-in-out", onClick: function () { return setOpenModalScannerQRCode(false); } }, "\u0110\u00F3ng"))))) :
        React.createElement("div", { className: "black-background justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50" },
            React.createElement("div", { className: "relative" },
                React.createElement("div", { className: "border-0 rounded-lg shadow-lg relative flex flex-col bg-white" },
                    React.createElement("div", { className: "flex justify-center items-center rounded-t mb-0" },
                        React.createElement("h4", { className: "text-xl font-sans font-semibold my-5" }, "Qu\u00E9t m\u00E3 QR")),
                    React.createElement("div", { className: "text-lg text-center mx-4" },
                        React.createElement("p", { className: "w-full my-0" }, "Vui l\u00F2ng truy c\u1EADp tr\u00EAn mobile \u0111\u1EC3 s\u1EED d\u1EE5ng t\u00EDnh n\u0103ng n\u00E0y")),
                    React.createElement("div", { className: "modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md" },
                        React.createElement("button", { type: "button", className: "button-l w-full load-more-btn text-white inline-block px-8 py-2 font-medium text-xs leading-tight rounded-full shadow-md transition duration-150 ease-in-out", onClick: function () { return setOpenModalScannerQRCode(false); } }, "\u0110\u00F3ng")))));
};
export default ModalScannerQrCode;
