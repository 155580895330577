import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'tw-elements';
import './services/NetworkConnector.ts';
import App from './App';
import './i18n';
// const App = Loadable(
//   {
//       loader: () => import('./App'),
//       loading: () => <Loading />,
//       render(loaded, props: any) {
//           const Alerts = loaded.default;
//           return <Alerts {...props} />
//       }
//   }
// );
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(React.createElement(React.Fragment, null,
    React.createElement(App, null)));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
