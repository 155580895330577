import { createSlice } from '@reduxjs/toolkit';
import { fetchCountUsersReportNew } from './thunks';
import { STATUS_LOADING, STATUS_SUCCEEDED, STATUS_FAILED } from '../../utils/Constants';
var initialState = {
    data: null,
    status: "idle",
    error: ""
};
var brandsSlice = createSlice({
    name: 'brands',
    initialState: initialState,
    reducers: {
        setCountUserReportInfo: function (state, _a) {
            var data = _a.payload.data;
            state.data = data;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(fetchCountUsersReportNew.pending, function (state, action) {
            state.status = STATUS_LOADING;
        })
            .addCase(fetchCountUsersReportNew.fulfilled, function (state, action) {
            state.status = STATUS_SUCCEEDED;
            state.data = action.payload;
        })
            .addCase(fetchCountUsersReportNew.rejected, function (state, action) {
            state.status = STATUS_FAILED;
            state.error = action && action.error && action.error.message ? action.error.message : "";
        });
    }
});
export var setCountUserReportInfo = brandsSlice.actions.setCountUserReportInfo;
//Fetch User Report
export var selectCountUsersReport = function (state) { return state.brands ? state.brands.data : null; };
export var getCountUsersReportError = function (state) { return state.brands ? state.brands.error : ""; };
export var getCountUsersReportStatus = function (state) { return state.brands ? state.brands.status : ""; };
export default brandsSlice.reducer;
