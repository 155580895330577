var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useRef } from 'react';
import ShoppingCartIcon from '../../../../assets/images/new_cart.png';
import ShoppingCartTrashIcon from '../../../../assets/images/trash-b.png';
import ShoppingCartEmpty from '../../../../assets/images/cart-empty.png';
import "./shopping-cart.scss";
import { adjustItemShoppingCartView, deleteItemShoppingCartView, getShoppingCartView, saveStoreShoppingCart, syncShoppingCartView } from '../../../../services/cart-serivce';
import { formatNumber, formatPercentage, formatValueByType, navigateToUrl, notificationSimple } from '../../../../utils/CommonUtil';
import CountInput from './components/count-input/CountInput';
import { useAppSelector } from '../../../../utils/hook';
import { getDataShoppingCart } from '../../../../store/shopping-cart/slice';
import * as Constant from '../../../../utils/Constants';
import { useTranslation } from 'react-i18next';
import { getAccessToken } from '../../../../utils/LocalStorageUtil';
import { Link, useNavigate } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';
import { cloneDeep } from 'lodash';
import { PlayIcon } from '@heroicons/react/24/solid';
import { userStorage } from 'services';
import CartTree from './CartTree';
import { calculateCarts } from 'utils/CartUtil';
var ShoppingCartDropdown = function (props) {
    var navigate = useNavigate();
    var t = useTranslation().t;
    var userShoppingCartStore = useAppSelector(getDataShoppingCart);
    var _a = useState(false), openShoppingCart = _a[0], setOpenShoppingCart = _a[1];
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(), shoppingCartData = _c[0], setShoppingCartData = _c[1];
    var _d = useState(false), showVideo = _d[0], setShowVideo = _d[1];
    useEffect(function () {
        setShoppingCartData(userShoppingCartStore);
    }, [userShoppingCartStore]);
    var useOutsideAlerter = function (ref) {
        useEffect(function () {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenShoppingCart(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return function () {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    var shoppingCartRef = useRef(null);
    useOutsideAlerter(shoppingCartRef);
    var addAndRemoveShoppingCart = function (item, numOfItem) { return __awaiter(void 0, void 0, void 0, function () {
        var body, data, error_1, message;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    body = {
                        itemId: item.itemId,
                        itemType: Number(item.itemType),
                        numOfItem: numOfItem,
                        itemResourceId: item.itemResource ? item.itemResource.id : null
                    };
                    return [4 /*yield*/, adjustItemShoppingCartView(body)];
                case 1:
                    data = _a.sent();
                    setShoppingCartData(data);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    message = error_1 && error_1.error && error_1.error.message ? error_1.error.message : t("error.general");
                    notificationSimple(t(message), Constant.NOTIFICATION_ERROR);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var deleteShoppingCart = function (item, numOfItem) { return __awaiter(void 0, void 0, void 0, function () {
        var body, data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    body = {
                        itemId: item.itemId,
                        itemType: item.itemType,
                        numOfItem: numOfItem,
                        itemResourceId: item.itemResource ? item.itemResource.id : null
                    };
                    return [4 /*yield*/, deleteItemShoppingCartView(body)];
                case 1:
                    data = _a.sent();
                    setShoppingCartData(data);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onFilterForStoreCart = function (shoppingCartData) {
        var shoppingCartItem = __assign({}, shoppingCartData);
        if (shoppingCartItem && shoppingCartItem.shoppingCartDetails && shoppingCartItem.shoppingCartDetails.length) {
            shoppingCartItem.shoppingCartDetails = shoppingCartItem.shoppingCartDetails.filter(function (shoppingCartDetail) { return shoppingCartDetail && shoppingCartDetail.id != "TOTAL"; });
        }
        saveStoreShoppingCart(shoppingCartItem);
    };
    var onChangeCart = function (type, item, numOfItem) {
        var shoppingCart = calculateCarts(shoppingCartData, item, numOfItem);
        switch (type) {
            case "CHANGE":
                // addAndRemoveShoppingCart(item, numOfItem);
                onFilterForStoreCart(shoppingCart);
                break;
            case "DELETE":
                deleteShoppingCart(item, numOfItem);
                break;
        }
    };
    var getShoppingCart = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, productItemNames_1, dataShoppingCart, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!userStorage.get())
                        return [2 /*return*/];
                    if (userShoppingCartStore)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setLoading(true);
                    return [4 /*yield*/, getShoppingCartView()];
                case 2:
                    data = _a.sent();
                    productItemNames_1 = [];
                    dataShoppingCart = cloneDeep(data);
                    if (dataShoppingCart && dataShoppingCart.shoppingCartDetails && dataShoppingCart.shoppingCartDetails.length) {
                        dataShoppingCart.shoppingCartDetails = (dataShoppingCart.shoppingCartDetails || []).filter(function (shoppingCartItem) {
                            if (shoppingCartItem && shoppingCartItem.message) {
                                productItemNames_1.push("".concat(shoppingCartItem.item && shoppingCartItem.item.name ? shoppingCartItem.item.name + " - " : "").concat(shoppingCartItem.itemResource && shoppingCartItem.itemResource.name ? shoppingCartItem.itemResource.name : ""));
                                return false;
                            }
                            return true;
                        });
                    }
                    if (productItemNames_1 && productItemNames_1.length) {
                        notificationSimple("".concat(productItemNames_1.join(", "), " ").concat(t("notification.cart_end_session")), Constant.NOTIFICATION_INFO);
                    }
                    setShoppingCartData(dataShoppingCart);
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    err_2 = _a.sent();
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClick = function (event) {
        setOpenShoppingCart(!openShoppingCart);
        if (!openShoppingCart) {
            getShoppingCart();
        }
    };
    var handleCheckOut = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, body;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    accessToken = getAccessToken();
                    if (!accessToken) return [3 /*break*/, 2];
                    body = shoppingCartData.shoppingCartDetails.filter(function (item) { return (item.numOfItem > 0 && item.id != "TOTAL"); }).map(function (item) {
                        return {
                            itemId: item.itemId,
                            numOfItem: item.numOfItem,
                            itemType: +item.itemType,
                            itemResourceId: item.itemResource ? item.itemResource.id : null
                        };
                    });
                    return [4 /*yield*/, syncShoppingCartView(body)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    navigateToUrl("/cart-detail");
                    return [2 /*return*/];
            }
        });
    }); };
    var handlePlayClick = function (event) {
        event.stopPropagation();
        setShowVideo(true);
    };
    return (React.createElement("div", { className: "dropdowns-wrapper mr-3 max-[500px]:mr-0", ref: shoppingCartRef },
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("div", { className: "notifications dropdown dd-trigger" },
                React.createElement("a", { className: "\n                            cursor-pointer\n                            text-gray-500\n                            hover:text-gray-700\n                            focus:text-gray-700\n                            mr-4\n                            dropdown-toggle\n                            hidden-arrow\n                            flex items-center\n                        ", onClick: handleClick, role: "button", "aria-expanded": "false" },
                    React.createElement("img", { className: 'w-8 h-8 max-sm:w-7 max-sm:h-7', src: ShoppingCartIcon, alt: "obranding-notification" }),
                    shoppingCartData && Array.isArray(shoppingCartData.shoppingCartDetails) && shoppingCartData.shoppingCartDetails.length > 0 &&
                        React.createElement("span", { className: "notifications-number text-brand bg-red-700 absolute rounded-full text-xs -mt-4 ml-4 py-0 px-1.5" }, shoppingCartData.shoppingCartDetails.length))),
            openShoppingCart && (React.createElement("div", { className: "custom-select-dropdown shopping-cart-dropdown custom-select-notification arrow", style: { position: "absolute", zIndex: 1000 } }, shoppingCartData && shoppingCartData.shoppingCartDetails && shoppingCartData.shoppingCartDetails.length ? React.createElement("div", { className: "shopping-cart-body", id: "scrollableNotification" },
                shoppingCartData.shoppingCartDetails.map(function (shoppingCart, index) {
                    return React.createElement("div", { key: index, className: "shopping-cart-detail" },
                        React.createElement("div", { className: 'shopping-cart-detail-info flex flex-row' },
                            React.createElement("div", { className: "shopping-cart-image-wrapper" },
                                React.createElement("div", { className: "shopping-cart-image" }, shoppingCart.itemResource ?
                                    React.createElement(React.Fragment, null, shoppingCart.itemResource.resourceType == 1 ?
                                        React.createElement(React.Fragment, null,
                                            !showVideo && (React.createElement("div", { className: 'relative' },
                                                React.createElement("img", { className: 'shopping-cart-small-image', src: shoppingCart.itemResource.thumbnailUrl ? shoppingCart.itemResource.thumbnailUrl : "", alt: "Thumbnail" }),
                                                React.createElement(PlayIcon, { onClick: handlePlayClick, className: 'shopping-cart-video-button-thumbnail' }))),
                                            showVideo && (React.createElement(Vimeo, { className: "main-media-video", video: shoppingCart.itemResource.resourceUrl ? shoppingCart.itemResource.resourceUrl : "", autoplay: true, width: "64", loop: true, background: true })))
                                        : React.createElement("img", { className: 'shopping-cart-small-image', src: shoppingCart.itemResource.resourceUrl ? shoppingCart.itemResource.resourceUrl : "", alt: "obranding-cart-image", width: "64" }))
                                    : React.createElement("img", { className: 'shopping-cart-small-image', src: shoppingCart.item.imageUrl ? shoppingCart.item.imageUrl : "", alt: "obranding-cart-image", width: "64" }))),
                            React.createElement("div", { className: 'grid grid-cols-5 shopping-cart-text w-full' },
                                React.createElement("div", { className: 'col-span-5 sm:col-span-3' },
                                    React.createElement("p", { className: 'title-l' }, "".concat(shoppingCart.item && shoppingCart.item.name ? shoppingCart.item.name + " - " : "").concat(shoppingCart.itemResource && shoppingCart.itemResource.name ? shoppingCart.itemResource.name : "")),
                                    React.createElement("p", { className: 'shopping-cart-text-default body-l' }, shoppingCart.item && shoppingCart.item.brandOwner ? shoppingCart.item.brandOwner.name : "")),
                                React.createElement("div", { className: 'col-span-5 w-full sm:col-span-2' },
                                    React.createElement("span", { className: 'shopping-cart-text-curency body-l text-left sm:text-right' },
                                        shoppingCart && shoppingCart.totalVNDPrice ? formatValueByType(shoppingCart.totalVNDPrice, "", 2) : 0,
                                        " VND"),
                                    shoppingCart && shoppingCart.totalVNDOriginalPrice && shoppingCart.totalVNDOriginalPrice > 0 ? React.createElement("div", { className: 'shopping-cart-text-curency shopping-cart-text-list-curency font-normal text-sm leading-6 line-through text-secondary' }, "".concat(shoppingCart.totalVNDOriginalPrice ? formatNumber(+shoppingCart.totalVNDOriginalPrice, 2) : 0, " VND ")) : null,
                                    shoppingCart.salePromotion && shoppingCart.salePromotion.value ? React.createElement("div", { className: 'shopping-cart-text-sale-promotion text-[#0048FF] mt-0 mb-1' },
                                        React.createElement("span", null,
                                            "\u00C1p d\u1EE5ng gi\u1EA3m ",
                                            formatPercentage(Number(shoppingCart.salePromotion.value.discount) > 100 ? 100 : Number(shoppingCart.salePromotion.value.discount), 0))) : null),
                                React.createElement("div", { className: 'col-span-5 shopping-cart-detail-actions w-full' },
                                    React.createElement("div", { className: 'shopping-cart-detail-actions-left' },
                                        React.createElement(CountInput, { numOfItem: shoppingCart && shoppingCart.numOfItem ? shoppingCart.numOfItem : 1, onChangeCart: function (numOfItem) { return onChangeCart("CHANGE", shoppingCart, numOfItem); }, remaining: shoppingCart.item && shoppingCart.item.remaining ? shoppingCart.item.remaining : null })),
                                    React.createElement("a", { className: 'shopping-cart-detail-actions-right', onClick: function () { return onChangeCart("DELETE", shoppingCart, shoppingCart && shoppingCart.numOfItem ? shoppingCart.numOfItem : 1); } },
                                        React.createElement("img", { src: ShoppingCartTrashIcon, alt: "obranding-trash-icon", className: "h-5 w-5 cursor-pointer" }))))),
                        React.createElement("div", { className: 'product-physical-wrapper col-span-5 w-full' },
                            React.createElement(CartTree, { carts: shoppingCart.subCartDetails, cartDesDetail: true })),
                        React.createElement("hr", { className: "shopping-cart-detail-diviver rounded my-5", color: '#F7F7F7' }));
                }),
                React.createElement("div", { className: 'shopping-cart-total' },
                    React.createElement("div", { className: 'shopping-cart-total-title' },
                        React.createElement("p", { className: 'button-l' }, t("shopping_cart.total"))),
                    React.createElement("div", { className: 'shopping-cart-total-price' },
                        React.createElement("p", { className: 'button-l text-right' },
                            shoppingCartData && shoppingCartData.totalVNDPrice ? formatValueByType(shoppingCartData.totalVNDPrice, "", 2) : 0,
                            " VND"))),
                React.createElement("div", { className: "mt-5" },
                    React.createElement("div", { className: "shopping-cart-checkout rounded-full bg-brand flex flex-row justify-center items-center cursor-pointer heading-l", onClick: handleCheckOut },
                        React.createElement(Link, { to: "/cart-detail", className: 'no-underline text-white w-full flex items-center justify-center' }, t("shopping_cart.checkout")))),
                React.createElement("div", { className: "mt-3 text-center" },
                    React.createElement("span", { className: 'body-l shopping-cart-checkout-note' }, t("shopping_cart.supported_VND_OPV")))) : React.createElement("div", { className: 'shopping-cart-modal-empty' },
                React.createElement("img", { src: ShoppingCartEmpty }),
                React.createElement("p", { className: 'shopping-cart-empty-des' }, t("shopping_cart.no_item_cart")))))),
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("div", { className: "messages dropdown" },
                React.createElement("span", { className: "fa fa-envelope-o" })))));
};
export default ShoppingCartDropdown;
